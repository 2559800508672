import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useRecoilValue } from "recoil";
import { usernameState } from "../atoms";
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import {logout} from "../services/auth";
import {Link, useHistory} from "react-router-dom";
import MenuList from "./MenuList"
import Menu from "@material-ui/core/Menu"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCalendarAlt, faCalculator, faUser, faBoxes} from "@fortawesome/fontawesome-free-solid"
import Grid from "@material-ui/core/Grid";
import logo from "../images/logo.png";
import MenuCascata from "./MenuCascata";
import MenuConsulta from "./MenuConsulta";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    title: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export default function Template(props) {
    const classes = useStyles();
    const theme = useTheme();
    const username = useRecoilValue(usernameState);
    const history = useHistory();

    const [openMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDrawerClose = () => {
        setOpenMenu(false);
    };


    return (
        <>
            <div className={classes.root}>
            <CssBaseline />
            <AppBar
                //position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: openMenu,
                })}
            >
                <Toolbar>
                    <Grid container={true} spacing={2} xs={12} sm={12}>
                        <Grid xs={5} sm={5} item style={{width: '50%'}}>
                            <MenuCascata/>
                        </Grid>
                        <Grid xs={2} sm={2} item>
                            {/*<center>
                                <Link to={"/dashboard"}>
                                    <img alt={"Logotipo"} width={"100px"} height={'100px'} src={logo}/>
                                </Link>
                            </center>*/}
                        </Grid>
                        <Grid xs={5} sm={5} item  >
                            <Grid spacing={0} container alignItems={"flex-end"} direction={"row-reverse"}>
                                {/*<Grid xs={3} item sm={3} >
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        className={clsx(classes.menuButton, openMenu && classes.hide)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>*/}
                                <Grid xs={2} item sm={2} >
                                    <center>
                                        <IconButton onClick={handleClick} variant="contained"  color="primary" ria-haspopup="true">
                                            <FontAwesomeIcon color={"#fff"} size={'1x'} icon={faUser}/>
                                        </IconButton>
                                        <Typography style={{color: '#fff'}} >{username}</Typography>
                                    </center>
                                </Grid>
                                <Grid xs={2} item sm={2} >
                                    <center>
                                        <Link to={"/documentos"}>
                                            <IconButton variant="contained"  color="primary" ria-haspopup="true">
                                                <FontAwesomeIcon color={"#fff"} size={'1x'} icon={faCalculator}/>
                                            </IconButton>
                                        </Link>
                                        <Typography style={{color: '#fff'}} >Registos</Typography>
                                    </center>
                                </Grid>
                                <Grid xs={2} item sm={2} >
                                    <center>
                                        <Link to={"/compras"}>
                                            <IconButton variant="contained" color="primary" ria-haspopup="true">
                                                <FontAwesomeIcon color={"#fff"} size={'1x'} icon={faBoxes}/>
                                            </IconButton>
                                            <Typography style={{color: '#fff'}} >Compras</Typography>
                                        </Link>
                                    </center>
                                </Grid>
                                <Grid xs={2} item sm={2} >
                                    <center>
                                        <Link to={"/dashboard"}>
                                            <IconButton variant="contained" color="primary" ria-haspopup="true">
                                                <FontAwesomeIcon color={"#fff"} size={'1x'} icon={faCalendarAlt}/>
                                            </IconButton>
                                            <Typography style={{color: '#fff'}} >Agenda</Typography>
                                        </Link>
                                    </center>
                                </Grid>
                                <Grid xs={2} item sm={2} >
                                    <MenuConsulta/>
                                </Grid>
                            </Grid>




                            {/*<Button variant="contained" color="primary" ria-haspopup="true" onClick={handleClick}>
                        {username}
                    </Button>
                    <Button variant="contained" color="primary" ria-haspopup="true" onClick={handleClick}>
                        {username}
                    </Button>
                    <Button variant="contained" color="primary" ria-haspopup="true" onClick={handleClick}>
                        {username}
                    </Button>
                    */}

                            {/*MENUS*/}
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={()=>{
                                    logout()
                                    handleClose()
                                    history.push("/")

                                }}>Terminar sessão</MenuItem>
                            </Menu>

                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={openMenu}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <MenuList/>
            </Drawer>

            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: openMenu,
                })}
            >
                <div className={classes.drawerHeader} style={{marginBottom: 50}} />
                {props.children}
                {props.children.props.location.pathname!=="/dashboard"?(
                    <center>
                        <Button onClick={() => history.goBack()} type="button" color="primary">Voltar</Button>
                    </center>
                ):null}

            </main>
        </div>
    </>
    );
}

